<template>
  <div class="career-vacancy__filter-item">
    <div class="career-vacancy__filter-selected-wrapper">
      <div class="career-vacancy__filter-selected" @click="toggleSelect">
        <span class="career-vacancy__filter-selected-title">{{
          $t(placeholder)
        }}</span>
        {{ selectedItem }}
      </div>
    </div>
    <div class="career-vacancy__filter-dropdown is-active" v-if="isShowSelect">
      <div v-if="items" class="career-vacancy__filter-list">
        <div
          v-for="item in items"
          :key="item.id"
          class="career-vacancy__filter-select"
          :class="{
            active: getActiveClass(item),
          }"
          @click="selectItem(item)"
        >
          {{
            item.attributes.vacancy
              ? item.attributes.vacancy.data.attributes[field]
              : item.attributes[field]
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { actionTypes } from "@/store/modules/vacancy";
export default {
  name: "VacancyFormSelect",
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    firstSelect: {
      type: Object,
    },
    field: {
      type: String,
      required: true,
    },
    getField: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isShowSelect: false,
      selectedItem: null,
    };
  },
  mounted() {
    this.selectedItem = this.firstSelect?.attributes.vacancy
      ? this.firstSelect?.attributes?.vacancy?.data?.attributes[this.field]
      : this.firstSelect?.attributes[this.field];
    console.log("firstSelect", this.firstSelect);
  },
  watch: {
    firstSelect: {
      handler() {
        this.selectedItem = this.firstSelect?.attributes[this.field];
      },
    },
  },
  methods: {
    ...mapActions("searchModule", {
      addFilter: actionTypes.removeFilter,
    }),
    selectItem(item) {
      if (item.attributes.vacancy) {
        this.selectedItem = item.attributes.vacancy.data.attributes[this.field];
      } else {
        this.selectedItem = item.attributes[this.field];
      }
      this.isShowSelect = false;
      console.log(item);
      switch (this.getField) {
        case "id":
          if (item.attributes.vacancy) {
            this.$emit("input", item.attributes.vacancy.data.id);
          } else {
            this.$emit("input", item.id);
          }
          break;
        case "object":
          this.$emit("input", item);
          break;
        default:
          this.$emit("input", item.attributes[this.getField]);
          break;
      }
    },
    toggleSelect() {
      this.isShowSelect = !this.isShowSelect;
    },
    getFilterSelect(item) {
      if (this.getField === "object") {
        return item;
      } else {
        if (item === this.items[0]) {
          return this.$t(item.attributes[this.field]);
        } else {
          return item.attributes[this.field];
        }
      }
    },

    getActiveClass(item) {
      if (item.attributes.vacancy) {
        return (
          item.attributes.vacancy.data.attributes[this.field] ===
          this.selectedItem
        );
      } else {
        return item.attributes[this.field] === this.selectedItem;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.career-vacancy__filter-selected-wrapper {
  z-index: 10;
}
.career-vacancy__filter-selected {
  width: 100%;
  min-height: 66px;
}

.career-vacancy__filter-selected {
  letter-spacing: -0.5px;
  color: #2d2f32;
  padding: 11px 20px;
  &-title {
    display: block;
    font-size: 16px;
    line-height: 20px;
    color: #999999;
  }
}

.career-vacancy__filter-dropdown.is-active {
  margin-top: -10px;
}
</style>
